// extracted by mini-css-extract-plugin
export var cloudAppDevBenefitsSection = "L_hp";
export var cloudAppDevBusinessSection = "L_hj";
export var cloudAppDevCasesSection = "L_hr";
export var cloudAppDevIndustriesSection = "L_hk";
export var cloudAppDevModelSection = "L_hh";
export var cloudAppDevOurAchievementsSection = "L_hn";
export var cloudAppDevPrimeSection = "L_hf";
export var cloudAppDevProcessSection = "L_hl";
export var cloudAppDevProjLogoSection = "L_hq";
export var cloudAppDevQuotesSection = "L_hs";
export var cloudAppDevServicesSection = "L_hg";
export var cloudAppDevTechStackSection = "L_hm";